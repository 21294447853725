import React from "react"

import { Link } from "gatsby"
import ArticleLayout from "../layouts/articleLayout"
import SEO from "../components/seo"

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

const PrivacyTemplate = ({ pageContext }) => {
  const rows = pageContext.airtable
  const { logos } = pageContext
  const categorySort = [
    `studies`,
    `projects`,
    `frameworks`,
    `podcasts`,
    `articles`,
  ]
  const categories = [...new Set(rows.map((item) => item.node.data.category))]

  categories.sort(function (a, b) {
    return categorySort.indexOf(a) - categorySort.indexOf(b)
  })

  return (
    <ArticleLayout headerImg={null}>
      <SEO
        title="Privacy Experience Resources"
        description="Resources for product teams to design better privacy experiences."
      />
      <h2>Privacy Experience Resources</h2>
      <p>
        People-centred resources for product teams that want to design better
        {` `}
        <Link to="/privacy-experience">privacy experiences</Link>.
      </p>

      <section>
        <ul>
          {categories.map((section) => (
            <li key={`categoryLink-${section}`}>
              <a href={`#${section}`}>{toTitleCase(section)}</a>
            </li>
          ))}
        </ul>
      </section>
      {categories.map((categorySection) => (
        <section key={`categorySection-${categorySection}`} className="u-mb++">
          <h3 id={`${categorySection}`} className="u-mb">
            {toTitleCase(categorySection)}
          </h3>
          <div>
            {rows &&
              rows.map((item) => {
                const {
                  category,
                  source_name: sourceName,
                  name,
                  url,
                  quote,
                  description,
                  // created_at,
                  // annotations,
                  // favicon,
                  // featured,
                  // podcast_links,
                } = item.node.data
                const { id } = item.node
                const newURL = new URL(url).host
                const { logo } = logos.find(
                  (logoData) => logoData.url === newURL
                )
                if (category === categorySection) {
                  return (
                    <div key={id} className="u-mb+">
                      <div
                        style={{
                          display: `flex`,
                          justifyContent: `flex-start`,
                          alignItems: `center`,
                        }}
                      >
                        {logo ? (
                          <img
                            src={`data:image/png;base64, ${logo}`}
                            alt={`Logo for ${newURL}`}
                            style={{
                              width: `48px`,
                              borderRadius: `100%`,
                              marginRight: `16px`,
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              width: `48px`,
                              height: `48px`,
                              borderRadius: `100%`,
                              marginRight: `16px`,
                              display: `block`,
                              border: `2px solid #fc7503`,
                            }}
                          />
                        )}

                        <div>
                          <h4 className="">{sourceName}</h4>
                          <h4 className="lowercase u-mb--">
                            <a href={`${url}`} target="_blank" rel="noreferrer">
                              {name}
                            </a>
                          </h4>
                        </div>
                      </div>
                      {quote && (
                        <div
                          className="entry-content"
                          style={{ position: `relative`, zIndex: 1 }}
                        >
                          <blockquote
                            className="discrete"
                            dangerouslySetInnerHTML={{
                              __html: quote.split(`\\n`).join(`<br>`),
                            }}
                          />
                        </div>
                      )}
                      {description && (
                        <div
                          className="entry-content"
                          style={{ position: `relative`, zIndex: 1 }}
                        >
                          <p
                            dangerouslySetInnerHTML={{
                              __html: description,
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )
                }
                return false
              })}
          </div>
        </section>
      ))}
      <p>
        Learn more about building trust with your customers by{` `}
        <Link to="/privacy-experience">going beyond compliance</Link>.
      </p>
    </ArticleLayout>
  )
}

// PrivacyTemplate.propTypes = {
//   pageContext: PropTypes.object.isRequired,
// }

export default PrivacyTemplate

// export const pageQuery = graphql`
//   query {
//     allMdx {
//       edges {
//         node {
//           frontmatter {
//             slug
//           }
//           body
//           id
//         }
//       }
//     }
//   }
// `
